<template>
    <div class="col-lg-12 col-md-12 col-sm-12 p-0" v-if="jobContent[0]">

        <div class="_job_detail_box light p-1">

            <div class="_job_details_single mb-0 pb-0">
                <div class="_jb_details01 mb-3 pb-3 ">

                    <div class="_jb_details01_flex">
                        <div class="_jb_details01_authors pl-0 mr-3 d-none d-lg-block" >
                            <img src="https://via.placeholder.com/100x100" class="img-fluid" alt="" />
                        </div>
                        <div class="_jb_details01_authors_caption pl-0">
                            <h4 class="jbs_title">{{ jobContent[0].title }} <i class="bi bi-patch-check-fill"></i> </h4>
                            <ul class="jbx_info_list">
                                <li><span><i class="bi bi-briefcase"></i>{{ jobContent[0].username }}</span></li>
                                <li><span v-if="jobContent[0]">
                                    <i class="bi bi-stack"></i>
                                    {{ jobContent[0].category }}
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        <i class="bi bi-geo-fill"></i>
                                          {{ JSON.parse(jobContent[0].job_location)['country'] }} ,
                                          {{ JSON.parse(jobContent[0].job_location)['city'] }}
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        <i class="bi bi-clock"></i>
                                          {{ new Date(jobContent[0].end_date).toLocaleDateString("en-US") }}
                                    </span>
                                </li>
                            </ul>
                            <ul class="jbx_info_list">
                                <li>
                                    <div class="jb_types bg-bage rounded">
                                        {{ jobContent[0].currency == 'n'?'&#8358;':'' }}
                                        {{ jobContent[0].currency == '$'?'$':'' }}
                                        {{ jobContent[0].currency == '£'?'£':'' }}
                                        {{ jobContent[0].currency == 'c'?'&#8373;':'' }}
                                        {{ jobContent[0].currency == 'e'?'&#8364;':'' }}
                                        {{ jobContent[0].salary_status == "none" ||
                                           jobContent[0].salary_status == "request_salary" ?
                                           'salary n/a ':jobContent[0].salary.toLocaleString("en") + ' ' +  placeholderOptions[jobContent[0].job_type]
                                        }}
                                    </div>
                                </li>
                                <li><div class="jb_types bg-bage rounded">{{ jobContent[0].job_type.replace("_","-") }}</div></li>
                                <li><div class="jb_types bg-bage rounded">Remote</div></li>
                            </ul>

                        </div>
                    </div>

                    <div class="_jb_details01_last">
                        <ul class="_flex_btn">
                            <li v-if="!applicationHistory.includes(jobContent[0].id)">
                                <span class="btn btn-secondary rounded small-font col-sm-12"
                                             @click="goToJobApply"
                                             style="color:#333" v-if="isAuthenticated">
                                    Save or Apply
                                </span>
                            </li>
                        </ul>
                    </div>


                </div>

                <div class="row mini-link pt-4">
                    <div class="col-3 pl-4 pt-1 text-center rounded-top cursor-pointer" :class="activeDetailsTab == 'jd'?'bg-bage':'' " @click="changeActiveDetailsTab('jd')">
                        Job
                    </div>
                    <div class="col-3 pt-1 text-center rounded-top cursor-pointer" :class="activeDetailsTab == 'bio'?'bg-bage':'' " @click="changeActiveDetailsTab('bio')">
                        Org
                    </div>
                    <div class="col-3 pt-1 text-center rounded-top cursor-pointer" :class="activeDetailsTab == 'reviews'?'bg-bage':'' " @click="changeActiveDetailsTab('reviews')">
                        Reviews
                    </div>
                    <div class="col-3 pt-1 text-center rounded-top cursor-pointer" :class="activeDetailsTab == 'benefits'?'bg-bage':'' " @click="changeActiveDetailsTab('benefits')">
                        Benefits
                    </div>
                </div>

            </div>

            <!-- start JD tab -->
            <div class="_job_detail_box_body p-3" id="job_details" v-if="activeDetailsTab === 'jd'">

                <div class="_job_detail_single pt-3">
                    <h4>Job Summary</h4>
                    <p> {{ jobContent[0].summary }} </p>
                </div>

                <div class="_job_detail_single">
                    <h4>Responsibilities</h4>
                    <ul>
                        <li v-for="(res, index) in JSON.parse(jobContent[0].responsibility)" :key="index">
                            <i class="bi bi-chevron-double-right"></i> {{ res }}
                        </li>
                    </ul>
                </div>

                <div class="_job_detail_single">
                    <h4>Qualification requirements</h4>
                    <ul>
                        <li v-for="(req, index) in JSON.parse(jobContent[0].requirement)" :key="index">
                            <i class="bi bi-chevron-double-right"></i> {{ req }}
                        </li>
                    </ul>
                </div>

                <div class="_job_detail_single">
                    <h4>Technical skills required</h4>
                    <ul class="skilss">
                        <li class="bg-bage rounded py-2 px-3" v-for="(sk, index) in JSON.parse(jobContent[0].skill)" :key="index">
                            {{ sk }}
                        </li>
                    </ul>
                </div>

                <div class="_job_detail_single flexeo">
                    <div class="_job_detail_single_flex">
                        <ul class="shares_jobs">
                            <li>Share The Job : </li>
                            <li><i class="bi bi-facebook facebook-color"></i></li>
                            <li><i class="bi bi-twitter twitter-color"></i></li>
                            <li><i class="bi bi-instagram instagram-color"></i></li>
                            <li><i class="bi bi-linkedin linkedin-color"></i></li>
                            <li><router-link data-dismiss="modal" :to="compDirectLink"><i class="bi bi-link-45deg"></i></router-link></li>
                        </ul>
                    </div>

                    <div class="_exlio_buttons">
                        <ul class="bottoms_applies" v-if="applicationHistory.includes(jobContent[0].id)">
                            <li>You have already applied for this job</li>
                        </ul>
                        <ul class="bottoms_applies" v-if="isAuthenticated && !(jobApply || jobSave) && !(applicationHistory.includes(jobContent[0].id))">
                            <li>
                                <a href="javascript:void(0)" @click="changeJobActions('save',true)" class="btn btn-outline-primary rounded px-2 py-1">
                                    <i class="bi " :Class="bookmarkHistory.includes(jobContent[0].id)?'bi-heart-fill':'bi-heart'"></i>
                                    {{ bookmarkHistory.includes(jobContent[0].id)?"Unsave":"Save" }}
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0)" @click="changeJobActions('apply',true)" class="btn btn-primary rounded px-2 py-1">
                                    <i class="bi bi-folder-plus"></i>
                                    Apply
                                </a>
                            </li>
                        </ul>
                        <ul class="bottoms_applies" v-if="!isAuthenticated">
                            <li>
                                <a :href="coreUrl.accountUrl+'login?platform=jobs'" class="btn btn-outline-primary rounded px-2 py-1">
                                    <i class="bi bi-lock"></i>
                                    Login to apply
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>

                <span id="job-apply-area"></span>
                <div class="_job_detail_single" v-if="isAuthenticated && jobApply">
                    <h4>
                        <span v-if="allFilterQs.length > 0">Answer the question(s) to complete application</span>
                        <span v-else>Click the button to complete application</span>
                        <span class="float-right">
                            <span @click="changeJobActions('apply',false)" class="bi bi-x-circle-fill cursor-pointer text-secondary"> close application</span>
                        </span>
                    </h4>
                    <div class="row ml-3 ">
                        <div class="col-12 p-2 row my-2" style="background:#f5f5f5"
                             v-for="(f,index) in JSON.parse(jobContent[0].filters)" :key="index">

                            <div class="col-lg-10 col-md-9 col-sm-12">

                                <b>{{ +index + 1 }}. </b>
                                {{ f.question }}

                            </div>
                            <div class="col-lg-2 col-md-3 col-sm-12">
                                <select class="form-control" :id="'filterAnswer'+index" style="height:30px;padding:0px" >
                                    <option value="">Answer</option>
                                    <option v-for="(r,index) in f.responseOptions"
                                            :value="r"
                                            :key="index">
                                            {{ r }}
                                    </option>
                                </select>
                            </div>

                        </div>

                    </div>
                    <div class="py-2" v-if="fv.reqFeedback.msg">

                        <base-list-tag :tagColor="fv.reqFeedback.val" >{{fv.reqFeedback.msg}}</base-list-tag>
                    </div>
                    <div  v-if="fv.showSubmit.status">
                            <button type="button" @click="tallyFilterAnswers" class="btn btn-save" v-if="true">Submit application</button>
                    </div>
                    <base-spinner class="ml-3" v-if="fv.showSubmit.val"></base-spinner>

                </div>


                <div class="_job_detail_single" v-if="isAuthenticated && jobSave">
                    <h4>
                        <span>
                            Are you sure you want to
                            {{ bookmarkHistory.includes(jobContent[0].id)?"unsave":"save" }}
                            this job ad ?
                        </span>
                    </h4>
                    <div class="py-2" v-if="fv.reqFeedback.msg">
                        <base-list-tag :tagColor="fv.reqFeedback.val" >{{fv.reqFeedback.msg}}</base-list-tag>
                    </div>
                    <div  v-if="fv.showSubmit.status">
                          <button type="button" @click="createBookmark" class="btn btn-primary" v-if="true">Yes</button>
                          <button type="button" @click="changeJobActions('save',false)" class="ml-2 btn btn-outline-primary" v-if="true">No</button>
                    </div>
                    <base-spinner class="ml-3" v-if="fv.showSubmit.val"></base-spinner>

                </div>

            </div>
            <!-- end JD tab -->

            <!-- start bio tab -->
            <div class="_job_detail_box_body p-3" id="org_details" v-if="activeDetailsTab === 'bio'">

                <div class="_job_detail_single pt-3">
                    <h4>
                        About {{ jobContent[0].username }}
                        <span class="tiny-font">
                         <em>( {{ jobContent[0].slogan }} )</em>
                        </span>
                    </h4>

                    <p> {{ jobContent[0].bio }} </p>
                </div>

                <div class="_job_detail_single pt-3">
                    <h4>
                        Organization size
                    </h4>

                    <p> {{ jobContent[0].size }} </p>
                </div>

                <div class="_job_detail_single pt-3">
                    <h4>
                        Areas of interest
                    </h4>

                    <ul class="skilss">
                        <li class="bg-bage rounded py-2 px-3 mr-3" v-for="(area, index) in JSON.parse(jobContent[0].areas_of_interest)" :key="index">
                            {{ area }}
                        </li>
                    </ul>
                </div>

                <div class="_job_detail_single pt-3">
                    <h4>
                        Website
                    </h4>

                    <p> {{ jobContent[0].website?jobContent[0].website:'No website available' }} </p>
                </div>

                <div class="_job_detail_single pt-3">
                    <h4>
                        Social details
                    </h4>

                    <ul class="skilss">
                        <li  v-for="(soc, index) in JSON.parse(jobContent[0].social_details)"
                             class="rounded py-2 px-3 mr-3"  :class="soc['media']+'-bg'"
                            :key="index">
                            <a  :href="soc.link" target="_blank" > <i class="mr-1 bi " :class="'bi-'+soc['media']"></i> {{ soc['media'] }} </a>
                        </li>
                    </ul>
                </div>


                <div class="_job_detail_single pt-3">
                    <div>
                        <h4>
                            Office location(s)

                            <span class="float-right text-primary normal-font"> * <i class="bi bi-building" ></i> main office </span>

                        </h4>

                    </div>

                    <div class="col-12 p-0">
                        <table class="table table-bordered">
                            <thead class="bg-bage">
                                <tr>
                                  <th >Office</th>
                                  <th class="col-6">Address</th>
                                  <th >Location</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(loc,index) in JSON.parse(jobContent[0].location_details)" :key="index">
                                  <td>{{ loc.name }} <i class="bi bi-building text-primary" v-if="loc.rank === 'default'"></i> </td>
                                  <td>{{ loc.address }}</td>
                                  <td>{{ loc.country }} , {{ loc.city }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
            <!-- end bio tab -->

            <!-- start reviews tab -->
            <div class="_job_detail_box_body p-3" id="review_details " v-if="activeDetailsTab === 'reviews'">

                <div class="_job_detail_single pt-3">
                    <h4>
                        All reviews
                    </h4>

                    <p> There are currently no reviews for this company.</p>
                </div>

            </div>

            <!-- start benefits tab -->
            <div class="_job_detail_box_body p-3" id="benefits_details" v-if="activeDetailsTab === 'benefits'">

                <div v-if="compBenefits.length > 0">
                    <div class="_job_detail_single pt-3" v-for="(type,index) in compBenefitType" :key="index">
                        <h4>
                            <i :class="benefitAlias[type]['icon']"></i> {{ benefitAlias[type]['alias'] }}
                        </h4>

                        <div v-for="(b,index) in compBenefits" :key="index">
                            <p v-if="type == b.type">
                                <i class="bi bi-chevron-double-right"></i>
                                {{ b.benefit }} {{ b.details }}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="_job_detail_single pt-3" v-else>
                    <h4> There are currently no listed benefits for this company.</h4>
                </div>

            </div>
            <!-- end benefits tab -->

        </div>
    </div>
</template>

<script>
import { ref,computed,inject,reactive } from 'vue'
import { useStore } from 'vuex'
export default{
    props:{
        jobContent:{
            type:Array,
            default:() => []
        },
        applicationHistory:{
            type:Array,
            default:() => []
        },
        bookmarkHistory:{
            type:Array,
            default:() => []
        }
    },
    emits:['emitUpdateJobHistory'],
    setup(props, { emit }){
        const axios = inject('axios');

        const coreUrl = inject('coreUrl');

        const store = useStore()

        // get the current status of client account
        const isAuthenticated = ref(store.getters["auth/getAuthAuthentication"].isAuthenticated);

        const placeholderOptions = {
            full_time:"per annum",
            part_time:"per annum",
            consultancy:"consultancy fee",
            freelance:"freelance fee",
            internship:"per annum",
            contract:"contract fee",
            volunteer:"volunteer fee"
        }

        const benefitAlias = {
            insurance_health_care:{alias:"Health Insurance",icon:"bi bi-shield-check"},
            work_life_balance:{alias:"Work life balance",icon:"bi bi-emoji-smile"},
            financial_pension:{alias:"Financial & Pension",icon:"bi bi-piggy-bank"},
            family_support:{alias:"Family Support",icon:"bi bi-people-fill"},
            free_perks:{alias:"Free perks",icon:"bi bi-gift"},
            professional_support:{alias:"Professional Support",icon:"bi bi-headset"}
        }

        const compBenefitType = computed(function(){
          let type = [];

          let benefitDetails = props.jobContent[0]['benefit_details']?JSON.parse(props.jobContent[0]['benefit_details']):[];

          for(let i = 0; i < benefitDetails.length ; i++){
              type.push(benefitDetails[i]["type"]);
          }

          type = [...new Set(type)];

          return type.sort();

      });

      const compBenefits = computed(function(){

        let benefits = props.jobContent[0]['benefit_details']?JSON.parse(props.jobContent[0]['benefit_details']):[];

        return benefits;

    });

    const compDirectLink = computed(function(){
        let linkTitle = props.jobContent[0].title.replace(/[^a-zA-Z0-9]/g,'_');
        return `/job_details/${linkTitle}/${props.jobContent[0].id}`;
    })

    const activeDetailsTab = ref("jd");

    function changeActiveDetailsTab(tab){
        activeDetailsTab.value = tab;
    }


    const jobApply = ref(false);
    const jobSave = ref(false);

    function changeJobActions(action, status){

        if(action == 'apply'){
            jobApply.value = status
            jobSave.value = false
        }

        if(action == 'save'){
            jobApply.value = false
            jobSave.value = status
        }


    }

    function goToJobApply(){
        changeJobActions('apply',true)
        let el = document.getElementById("job-apply-area");
        el.scrollIntoView({behavior: 'smooth'})
    }


    const fv = reactive({
        reqFeedback : {status:false, val:"", msg:false},
        showSubmit : {status:true, val:false}
    });

    const allFilterQs = ref(props.jobContent[0].filters?JSON.parse(props.jobContent[0].filters):[]);

    function tallyFilterAnswers(){

        fv.showSubmit.status = false;
        fv.showSubmit.val = true;

        let answerSet = []
        let answerFeedback = [];

        if(allFilterQs.value.length > 0){


            for(let i = 0; i < allFilterQs.value.length; i++){

                let newQuestion = allFilterQs.value[i]["question"]

                let newAnswer = document.getElementById("filterAnswer"+i).value;
                newAnswer?answerFeedback.push(true):answerFeedback.push(false)
                let answerKey = newQuestion.replace(/[^a-zA-Z0-9]/g,'_') + newAnswer.replace(/[^a-zA-Z0-9]/g,'_')
                let answerObj = {}
                answerObj[answerKey] = {question:newQuestion, answer:newAnswer}
                answerSet.push(answerObj)
            }

            if(answerFeedback.every(Boolean)){
                createJobApplication(answerSet)
            }else{
                fv.reqFeedback.status = true;
                fv.reqFeedback.msg = "Kindly answer all the screening questions";
                fv.reqFeedback.val = "danger";

                fv.showSubmit.status = true;
                fv.showSubmit.val = false;
            }

        }else{
            const filterAnswers = []
            createJobApplication(filterAnswers)
        }
    }

    // create the account using async
    async function createJobApplication(filterAnswers){

        // load the form data , and append all the necessary fields
        let formData = new FormData();
        formData.append("fv_jobId", props.jobContent[0]['id']);
        formData.append("fv_orgId", props.jobContent[0]['client_id']);
        formData.append("fv_filterAnswers", JSON.stringify(filterAnswers));


        // post the data using axios
        try{
            const response = await axios.post(
                coreUrl.backendUrl+'create_individual_job_application',
                formData,
                {
                    withCredentials: true,
                    headers:{
                       "Content-Type": "multipart/form-data"
                    }
                }
            );

            // If successful, get the resppnse data
            const responseData = response.data;

            fv.reqFeedback.status = true;
            fv.reqFeedback.msg = responseData.message.req_msg;
            fv.reqFeedback.val = "success";

            fv.showSubmit.status = false;
            fv.showSubmit.val = false;

            emit('emitUpdateJobHistory','applications',responseData.data)


        }catch(error){

            // load the error to the appropriate field
            const req_msg = error.response.data.message.req_msg;


            fv.reqFeedback.status = true;
            fv.reqFeedback.msg = req_msg;
            fv.reqFeedback.val = "danger";

            // end the action of making a submission, and hide loader
            fv.showSubmit.status = true;
            fv.showSubmit.val = false;

        }

    }

    async function createBookmark(){

        // load the form data , and append all the necessary fields
        let formData = new FormData();
        formData.append("fv_jobId", props.jobContent[0]['id']);


        // post the data using axios
        try{
            const response = await axios.post(
                coreUrl.backendUrl+'create_individual_job_bookmark',
                formData,
                {
                    withCredentials: true,
                    headers:{
                       "Content-Type": "multipart/form-data"
                    }
                }
            );

            // If successful, get the resppnse data
            const responseData = response.data;

            console.log(responseData)

            fv.reqFeedback.status = true;
            fv.reqFeedback.msg = responseData.message.req_msg;
            fv.reqFeedback.val = "success";

            fv.showSubmit.status = false;
            fv.showSubmit.val = false;

            emit('emitUpdateJobHistory','bookmarks',responseData.data)


        }catch(error){

            // load the error to the appropriate field
            const req_msg = error.response.data.message.req_msg;


            fv.reqFeedback.status = true;
            fv.reqFeedback.msg = req_msg;
            fv.reqFeedback.val = "danger";

            // end the action of making a submission, and hide loader
            fv.showSubmit.status = true;
            fv.showSubmit.val = false;

        }

    }


    return {
            fv,
            coreUrl,
            isAuthenticated,
            compBenefits,
            compBenefitType,
            benefitAlias,
            placeholderOptions,
            jobApply,
            jobSave,
            changeJobActions,
            compDirectLink,
            goToJobApply,
            allFilterQs,
            activeDetailsTab,
            changeActiveDetailsTab,
            tallyFilterAnswers,
            createBookmark
        }
    }
}
</script>

<style scoped>
.text-grey{
    color:#ccc;
}


</style>
